<template>
  <div class="login-page">
    <loginLayout :footerText="AUTH_TRANSLATE?.common.footer.company.label" :footerLink="AUTH_TRANSLATE?.common.footer.company.btn.register">
      <div class="content">
        <h2 class="r-title-2">{{ AUTH_TRANSLATE?.auth?.title }}</h2>
        <form class="form-inputs">
          <InputText @keyup.enter="login" v-model:value.trim="user.email" :errorText="VALIDATION_TRANSLATE?.login?.email" :valid="validEmail" :label="AUTH_TRANSLATE?.auth?.form.email"></InputText>
          <InputText @keyup.enter="login" v-model:value.trim="user.password" :errorText="VALIDATION_TRANSLATE?.login?.password" :valid="validPass" :type="passwordInputType" :label="AUTH_TRANSLATE?.auth?.form.password">
            <ButtonShowPass v-model:type="passwordInputType"></ButtonShowPass>
          </InputText>
        </form>
        <div class="form-btns">
          <Button @click="login">{{ AUTH_TRANSLATE?.auth?.form.btn.login }}</Button>
          <Button @click="goToPassRecovery" mode="white">{{ AUTH_TRANSLATE?.auth?.form.btn.restore }}</Button>
        </div>
      </div>
    </loginLayout>
  </div>
</template>

<script>
import ButtonShowPass from "../components/parts/button-show-pass";
import Button from "../components/parts/button";
import InputText from "../components/parts/input-text";
import loginLayout from "../components/loginLayout";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    loginLayout,
    Button,
    ButtonShowPass,
    InputText
  },
  data() {
    return {
      passwordInputType: "password",
      user: {
        email: "",
        password: ""
      }
    };
  },
  computed: {
    ...mapGetters(["AUTH_TRANSLATE", "VALIDATION_TRANSLATE"]),
    validEmail() {
      let pattern = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
      return pattern.test(this.user.email);
    },
    validPass() {
      return !(this.user.password.length < 8);
    }
  },
  methods: {
    ...mapActions(["Login", "AuthTranslate", "ValidationTranslate"]),
    login() {
      if (this.validEmail && this.validPass) {
        this.Login(this.user);
      }
    },
    goToPassRecovery() {
      this.$router.push({ name: "PasswordRecovery" });
    }
  },
  created() {
    this.AuthTranslate();
    this.ValidationTranslate();
  }
};
</script>

<style lang="scss">
.login-page {
  height: 100%;
  width: 100%;
  .content {
    padding: 49px 20px 19px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    h2 {
      font-weight: 400;
      color: var(--dark-100);
      margin-bottom: 41px;
    }
  }
  .form-inputs {
    display: flex;
    margin-bottom: 35px;
    .main {
      width: 100%;
      &:last-child {
        margin-left: 20px;
        .input {
          padding-right: 20px;
        }
      }
    }

    svg {
      color: var(--dark-40);
    }
    &:focus-within {
      svg {
        color: var(--blue-main);
      }
      .not-valid {
        svg {
          color: var(--error);
        }
      }
    }
  }

  .form-btns {
    display: flex;
    button.white {
      &:hover {
        background: var(--bg-white);
        color: var(--blue-main);
      }
    }
  }
  @media (max-width: 1199px) {
    .message-bottom {
      padding-bottom: 20px;
    }
  }
  @media (max-width: 1024px) {
    .left-box {
      min-width: 500px;
    }
    .form-inputs {
      flex-wrap: wrap;
      .main:first-child {
        margin-bottom: 30px;
      }
    }
    .content {
      padding-top: 85px;
      padding-right: 20px;
    }

    .form-inputs .main:last-child {
      margin-left: 0;
    }
  }
  @media (max-width: 764px) {
    .content {
      padding-top: 36px;
    }
    header > button {
      margin-right: 5px;
    }
    .login-layout header {
      background: var(--white-100);
      padding: 12px 0 12px 21px;
      img {
        margin: auto 0;
      }
    }

    .right-box {
      background-color: var(--bg-main);
      position: absolute;
      top: 0;
      margin-top: 72px;
      max-height: 98px;
      min-height: 98px;
    }
    .content {
      padding-top: 38px;
      padding-right: 20px;
      h2 {
        margin-bottom: 42px;
      }
    }
    .form-inputs {
      margin-bottom: 33px;
    }
    .main {
      min-width: 200px;
    }
    .left-box {
      width: 100%;
      min-width: 320px;
      padding-top: 170px;
    }
    .message-bottom {
      margin-top: 34px;
      padding-bottom: 0;
      p.m-text-des {
        padding-bottom: 17px;
      }
    }
  }
  @media (max-width: 370px) {
    .form-btns {
      flex-direction: column;
      .white {
        margin-top: 20px;
      }
    }
  }
}
</style>
